import React from 'react'
import LazyLoad from 'react-lazyload'
import { useTranslation } from 'react-i18next'
import Carousel from 'nuka-carousel'

import slideHome01 from '../assets/images/home/home01.webp'
import slideHome01b from '../assets/img/home/home01.jpg'
import slideHome02 from '../assets/images/home/home02.webp'
import slideHome02b from '../assets/img/home/home02.jpg'
import slideHome03 from '../assets/images/home/home03.webp'
import slideHome03b from '../assets/img/home/home03.jpg'
import slideHome04 from '../assets/images/home/home04.webp'
import slideHome04b from '../assets/img/home/home04.jpg'
import slideHome05 from '../assets/images/home/home05.webp'
import slideHome05b from '../assets/img/home/home05.jpg'
import slideHome06 from '../assets/images/home/home06.webp'
import slideHome06b from '../assets/img/home/home06.jpg'

const Slideshow = ({ moveToSection }) => {
  const { t } = useTranslation()

  return <div className="slideshow">
    <span className="backOp"></span>
    <Carousel 
      autoplay={true}
      wrapAround={true}
      swiping={true}
      speed={6000}
      transitionMode="fade" 
      defaultControlsConfig={{
        pagingDotsClassName: 'dot'
      }}>
        <LazyLoad height={500}>
          <picture>
            <source srcSet={slideHome01} type="image/webp" />
            <source srcSet={slideHome01b} type="image/jpeg" /> 
            <img src={slideHome01b} alt="" />
          </picture>
        </LazyLoad>
        <LazyLoad height={500}>
          <picture>
            <source srcSet={slideHome02} type="image/webp" />
            <source srcSet={slideHome02b} type="image/jpeg" /> 
            <img src={slideHome02b} alt="" />
          </picture>
        </LazyLoad>
        <LazyLoad height={500}>
          <picture>
            <source srcSet={slideHome03} type="image/webp" />
            <source srcSet={slideHome03b} type="image/jpeg" /> 
            <img src={slideHome03b} alt="" />
          </picture>
        </LazyLoad>
        <LazyLoad height={500}>
          <picture>
            <source srcSet={slideHome04} type="image/webp" />
            <source srcSet={slideHome04b} type="image/jpeg" /> 
            <img src={slideHome04b} alt="" />
          </picture>
        </LazyLoad>
        <LazyLoad height={500}>
          <picture>
            <source srcSet={slideHome05} type="image/webp" />
            <source srcSet={slideHome05b} type="image/jpeg" /> 
            <img src={slideHome05b} alt="" />
          </picture>
        </LazyLoad>
        <LazyLoad height={500}>
          <picture>
            <source srcSet={slideHome06} type="image/webp" />
            <source srcSet={slideHome06b} type="image/jpeg" /> 
            <img src={slideHome06b} alt="" />
          </picture>
        </LazyLoad>
      </Carousel>

    <div className="caption">
      <h1 className="tit">{t('slogan')}</h1>
      <a href="#contact" className="btn btnType2" onClick={(e) => moveToSection(e, 'contact')}>{t('contacto')}</a>
    </div>
  </div>
}

export default Slideshow;