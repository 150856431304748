import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "es",
    // we init with resources
    resources: {
      es: {
        translations: {
          descripcion: "Descripción",
          casa: "Casa principal",
          invitados: "de invitados",
          servicios: "Servicios",
          galeria: "Galería",
          contacto: "Contacto",
          slogan: "La casa de lujo de tus sueños en Camp de Mar con acceso directo al mar",
          descripcion1: "Una impresionante Villa que se erige sobre las rocas directamente del mar, rodeada de naturaleza.",
          descripcion2: "El espacio esta distribuido en un edificio principal y un edificio destinado a invitados y servicio. Ambos edificios están comunicados y rodeados de zonas comunes, terrazas, jacuzzi exterior con vistas al mar y a la montaña y una piscina infinity con las mejores vistas.",
          titVilla: "Villa principal",
          titVilla2: "Villa invitados",
          descVilla: "La casa principal tiene una superficie de 780m², distribuida en 4 dormitorios, con 5 baños en suite, un diáfano salón con chimenea, un aseo y una estilosa cocina con isla separada del salón con corredera de cristal, sus dos grandes terrazas con amplios ventanales que miran al mar te conectan con la naturaleza. Dispone de una espectacular escalera de cristal y un moderno ascensor.",
          descVilla2: "La casa de invitados tiene una superficie de 290m², está distribuida en 3 dormitorios con tres baños en suite y un amplio salón. Existe una entrada independiente que se distribuye en un apartamento con cocina americana, dos dormitorios y un baño para el servicio.",
          verFicha: "Ver ficha",
          habitaciones: "Habitaciones",
          banyos: "Baños",
          aseo: "Aseo",
          aireAcondicionado: "Aire acondicionado",
          calefaccion: "Calefacción",
          wifi: "Wi-fi",
          spa: "Spa",
          parking: "Parking",
          tvSatelite: "TV satélite / Astra",
          piscinaExt: "Piscina exterior",
          jacuzziExt: "Jacuzzi exterior",
          equipada: "Totalmente equipada",
          cuna: "Cuna",
          ascensor: "Ascensor",
          plegatin: "Plegatín",
          titEntorno: "Zona",
          descEntorno: "Esta localidad frente al mar de la zona suroeste de Mallorca, situada en el municipio de Andratx, es una zona turística sofisticada rodeada de hoteles exclusivos y es popular entre la gente de alto poder adquisitivo y los famosos, que acuden durante los meses de verano.",
          titEntorno2: "Servicios",
          descEntorno2: "La principal atracción de Camp de Mar es la playa de 180 metros de ancho, bien equipada. En ella podrás encontrar duchas, baños, hamacas, rampas para discapacitados, socorristas y velomares en alquiler. También cuenta con uno de los mejores campos de Golf de la isla. Con su famoso “Monster Green” el par 5 más largo de España.",
          titEntorno3: "Distancias",
          aeropuerto: "Aeropuerto",
          playa: "Playa",
          golf: "Campo de Golf",
          alquilerYventa: "Alquiler y Venta",
          tarifas: "Tarifas",
          precio1: "25.000€",
          precio2: "28.000€",
          precio3: "35.000€",
          precio4: "15 M €",
          noviembreAfebrero: "Noviembre a Febrero",
          marzoAmayo: "Marzo a Mayo",
          junioAoctubre: "Junio a Octubre",
          semana: "Semana",
          minimo: "Mínimo",
          dias: "Días",
          venta: "Venta",
          comoLlegar: "Cómo llegar",
          nombre: "Nombre",
          apellidos: "Apellidos",
          telefono: "Teléfono",
          email: "E-mail",
          llegada: "Llegada",
          salida: "Salida",
          pax: "Número de personas",
          enviar: "Enviar",
          superficie: "Superficie 780m²",
          plantas: "Plantas",
          banyosSuite: "baños en suite",
          apartamento: "Apartamento para servicio",
          apartamentoDesc: "Cocina americana + 2 habitaciones + 1 baño",
          sotano: "SÓTANO",
          sotanoDesc: "Piscina cubierta, baño, sauna y zona de descanso",
          plantaBaja: "Planta baja",
          plantaBajaDesc: "Salón, comedor, cocina totalmente equipada (electrodomésticos Gaggenau, lavavajillas congelador, nevera, vinoteca, horno, microondas, cafetera), aseo y terraza.",
          plantaPrimera: "Planta primera",
          plantaPrimeraDesc: "114m², 2 baños completos en suite, bañera exenta, 1 ducha, salón integrado, aseo independiente y terraza privada.",
          habitacionPrincipal: "Habitación 1 principal",
          plantaSegunda: "Planta segunda",
          plantaSegundaDesc1: "57m², baño en suite con ducha y terraza.",
          plantaSegundaDesc2: "38m², baño en suite con ducha y terraza.",
          plantaSegundaDesc3: "44m², baño en suite con ducha.",
          terceraPlanta: "Planta tercera",
          terceraPlantaDesc: "200m² de terraza con unas vistas de 360º.",
          solarium: "Solarium",
          habitacion: "Habitación",
          entradaIndependiente: "Entrada independiente",
          salonesYterrazas: "Salones y terrazas",
          plantaPrimeraDesc2: "baño en suite con ducha.",
          plantaSegundaDesc4: "La casa del servicio se distribuye en 2 habitaciones con un baño independiente y una cocina americana.",
          footer: "©2020 Todos los derechos reservados."
        }
      },
      en: {
        translations: {
          descripcion: "Description",
          casa: "Main House",
          invitados: "Guest House",
          servicios: "AMENITIES",
          galeria: "Photo Gallery",
          contacto: "Contact",
          slogan: "The luxury home of your dreams in Camp de Mar with direct access to the sea",
          descripcion1: "A magnificent villa standing on the rocks directly overlooking the sea in a breathtaking natural setting.",
          descripcion2: "The space comprises a main building and a second building for guest and staff accommodation. Both buildings are set among communal areas, terraces, an outdoor jacuzzi with sea and mountain views, and an infinity pool boasting unmatchable views.",
          titVilla: "Main Villa",
          titVilla2: "Guest Villa",
          descVilla: "The main villa has a surface area of 780m², divided into 4 bedrooms with 5 ensuite bathrooms, an open-plan living room with a fireplace, a guest toilet and stylish kitchen with an island separated from the living room by a glass sliding door. Other features include 2 spacious terraces with large picture windows affording stunning sea views and which create a sensation of being in direct contact with nature. A glass staircase and modern lift connect all 4 floors.",
          descVilla2: "The guest villa and staff accommodation have a surface area of 290m², divided into 3 bedrooms and three ensuite bathrooms and a spacious living room. There is a separate entrance leading to the staff accommodation comprising 2 bedrooms, a kitchenette and bathroom.",
          verFicha: "VIEW SPECIFICATIONS",
          habitaciones: "Bedrooms",
          banyos: "BATHROOMS",
          aseo: "GUEST TOILET",
          aireAcondicionado: "AIR CONDITIONING",
          calefaccion: "HEATING",
          wifi: "Wifi",
          spa: "Spa",
          parking: "Parking Area",
          tvSatelite: "ASTRA / SATELLITE TV",
          piscinaExt: "OUTDOOR SWIMMING POOL",
          jacuzziExt: "OUTDOOR JACUZZI",
          equipada: "FULLY EQUIPPED",
          cuna: "COT",
          ascensor: "Lift",
          plegatin: "FOLDING BED",
          titEntorno: "Location",
          descEntorno: "This seafront location in the south-west of Majorca in the municipality of Andratx, is a sophisticated tourist resort surrounded by exclusive hotels and a popular choice with affluent visitors and celebrities, who frequent this area in the summer months.",
          titEntorno2: "Amenities",
          descEntorno2: "Camp de Mar’s principal attraction is the 180 metre wide beach, offering a full range of facilities and services including showers, toilets, sun beds, ramps for the disabled, lifeguards and pedalos for hire. It also boasts one of the island’s finest golf courses, which features the famous “Monster Green”, Spain’s longest par 5 hole.   ",
          titEntorno3: "Distances",
          aeropuerto: "Airport",
          playa: "Beach",
          golf: "Golf Course",
          alquilerYventa: "RENTAL AND SALE",
          tarifas: "Tarifas",
          precio1: "€23.000",
          precio2: "€26.000",
          precio3: "€30.000",
          precio4: "€15 m",
          noviembreAfebrero: "November to February",
          marzoAmayo: "March to May",
          junioAoctubre: "June to October",
          semana: "per week",
          minimo: "MINIMUM STAY",
          dias: "Days",
          venta: "Sale price",
          comoLlegar: "HOW TO GET THERE",
          nombre: "Name",
          apellidos: "Surnames",
          telefono: "Telephone",
          email: "E-mail",
          llegada: "ARRIVAL DATE",
          salida: "Departure",
          pax: "NUMBER OF GUESTS:",
          enviar: "Send",
          superficie: "Surface area",
          plantas: "Floors",
          banyosSuite: "Ensuite bathrooms",
          apartamento: "Service apartment",
          apartamentoDesc: "Kitchenette + 2 rooms + 1 bath",
          sotano: "BASEMENT",
          sotanoDesc: "Spa (Indoor swimming pool, bathroom, sauna and relaxation zone).",
          plantaBaja: "GROUND FLOOR",
          plantaBajaDesc: "Living room, dining room, fully equipped kitchen (Gaggenau electrical appliances, dishwasher, freezer, fridge, wine cooler, oven, microwave, coffee maker), guest toilet and terrace.",
          plantaPrimera: "FIRST FLOOR",
          plantaPrimeraDesc: "114m², 2 fully equipped ensuite bathrooms, free-standing bath tub and shower, integrated sitting room and private terrace.",
          habitacionPrincipal: "Master bedroom ",
          plantaSegunda: "SECOND FLOOR",
          plantaSegundaDesc1: "57m², ensuite bathroom with shower and terrace.",
          plantaSegundaDesc2: "38m², ensuite bathroom with shower and terrace.",
          plantaSegundaDesc3: "44m², ensuite bathroom with shower.",
          terceraPlanta: "THIRD FLOOR",
          terceraPlantaDesc: "Sun terrace: 200m² terrace with 360º panoramic views.",
          solarium: "Solarium",
          habitacion: "Room",
          entradaIndependiente: "SEPARATE ENTRANCE",
          salonesYterrazas: "Living rooms and terraces",
          plantaPrimeraDesc2: "ensuite bathroom with shower",
          plantaSegundaDesc4: "The staff accommodation consists of 2 bedrooms with a separate bathroom and a kitchenette.  ",
          footer: "©2020 All rights reserved. "
        }
      },
      de: {
        translations: {
          descripcion: "Descripcionen",
          casa: "Haupthaus",
          invitados: " Gasthaus",
          servicios: "SERVICELEISTUNGEN ",
          galeria: "GALERIE",
          contacto: "KONTAKT",
          slogan: "Das Luxushaus Ihrer Träume in Camp de Mar",
          descripcion1: "Eine eindrucksvolle Villa, die sich direkt aus dem Meer über die Felsen erhebt, mitten in der Natur.",
          descripcion2: "Der Wohnraum ist in ein Hauptgebäude und ein Gebäude für Gäste und Personal unterteilt. Beide Gebäude sind umgeben von Gemeinschaftsbereichen, Terrassen, Whirlpool im Freien mit Blick aufs Meer und die Berge sowie einem Infinity-Pool mit herrlichem Blick.",
          titVilla: "HAUPTVILLA",
          titVilla2: "GÄSTEVILLA",
          descVilla: "Das Hauptgebäude hat eine Wohnfläche von 780m², eingeteilt in 4 Schlafzimmer mit 5 En-Suite-Badezimmern, ein weitläufiges Wohnzimmer mit Kamin, eine Toilette und eine stilvolle Küche mit Kochinsel, die vom Wohnzimmer mit einer Glasschiebetür abgeteilt ist. Die beiden großen Terrassen mit breiten Fenstern bieten Blick aufs Meer und verbinden den Betrachter mit der Natur. Die 4 Etagen sind durch eine Glastreppe und einen moderner Aufzug verbunden",
          descVilla2: "Das Gäste- und Personalhaus hat eine Fläche von 290m² und ist eingeteilt in 3 Schlafzimmer und drei En-Suite-Badezimmer sowie ein weitläufiges Wohnzimmer. Zudem besteht ein unabhängiger Personaleingang mit 2 Schlafzimmern, einer amerikanischen Küche und einem Badezimmer.",
          verFicha: "DATENBLATT ANSEHEN",
          habitaciones: "SCHLAFZIMMER",
          banyos: "BADEZIMMER",
          aseo: "TOILETTE",
          aireAcondicionado: "KLIMAANLAGE",
          calefaccion: "HEIZUNG",
          wifi: "WLAN",
          spa: "Spa",
          parking: "PARKPLATZ",
          tvSatelite: "SATELLITENFERNSEHEN / ASTRA",
          piscinaExt: "SWIMMINGPOOL IM FREIEN ",
          jacuzziExt: "WHIRLPOOL IM FREIEN",
          equipada: "KOMPLETT AUSGESTATTET",
          cuna: "KINDERBETT",
          ascensor: "AUFZUG",
          plegatin: "KLAPPBETT",
          titEntorno: "Zone",
          descEntorno: "Dieser Ort am Meer im Südwesten von Mallorca gehört zum Gemeindegebiet von Andratx, einem erlesenen Fremdenverkehrsgebiet mit exklusiven Hotels. Die Gegend ist sehr beliebt bei wohlhabenden Bewohnern und Besuchern sowie bei den bekannten Persönlichkeiten, die in den Sommermonaten hierherkommen.",
          titEntorno2: "Serviceleistungen",
          descEntorno2: "Besonders interessant ist der gut ausgestattete, 180 Meter breite Strand von Camp de Mar. Dort befinden sich Duschen, Toiletten, Liegestühle, Rampen für Behinderte, Rettungsschwimmer und Tretboote zum Mieten.  Zudem kann die Gegend mit einem der besten Golfplätze der Insel mit seinem berühmten „Monster Green“, dem längsten Par 5 in Spanien, aufwarten.",
          titEntorno3: "Entfernungen",
          aeropuerto: "zum Flughafen",
          playa: "Strand",
          golf: "Golfplatz",
          alquilerYventa: "VERMIETUNG UND VERKAUF",
          tarifas: "Preise",
          precio1: "23.000 €/Woche",
          precio2: "26.000 €/Woche",
          precio3: "30.000 €/Woche",
          precio4: "15 Mio. €",
          noviembreAfebrero: "November bis Februar",
          marzoAmayo: "März bis Mai",
          junioAoctubre: "Juni bis Oktober",
          semana: "Woche",
          minimo: "MINDESTENS ",
          dias: "TAGE",
          venta: "Verkauf",
          comoLlegar: "ANFAHRT",
          nombre: "Name",
          apellidos: "NACHNAMEN",
          telefono: "Telefon",
          email: "E-mail",
          llegada: "ANKUNFT",
          salida: "Abfahrt",
          pax: "PERSONENZAHL",
          enviar: "Senden",
          superficie: "FLÄCHE",
          plantas: "STOCKWERKE",
          banyosSuite: "EN-SUITE-BÄDER",
          apartamento: "Apartamento para servicio",
          apartamentoDesc: "cocina americana + 2 habitaciones + 1 baño",
          sotano: "KELLER",
          sotanoDesc: "Spa (Hallenbad, Badezimmer, Sauna und Ruhebereich).",
          plantaBaja: "ERDGESCHOSS",
          plantaBajaDesc: "Wohnzimmer, Esszimmer, vollständig eingerichtete Küche (hochwertige Haushaltsgeräte, Spülmaschine, Tiefkühlschrank, Kühlschrank, Vinothek, Backofen, Dampfbackofen, Mikrowelle, Kaffeemaschine), Toilette und Terrasse.",
          plantaPrimera: "ERSTE ETAGE",
          plantaPrimeraDesc: "114m², 2 komplette En-Suite-Badezimmer, freistehende Badewanne und Dusche, integriertes Wohnzimmer und private Terrasse.",
          habitacionPrincipal: "Hauptschlafzimmer ",
          plantaSegunda: "ZWEITE ETAGE",
          plantaSegundaDesc1: "57m², En-Suite-Badezimmer mit Dusche und Terrasse.",
          plantaSegundaDesc2: "38m², En-Suite-Badezimmer mit Dusche und Terrasse.",
          plantaSegundaDesc3: "44m², En-Suite-Badezimmer mit Dusche.",
          terceraPlanta: "DRITTE ETAGE",
          terceraPlantaDesc: "Sonnenterrasse: 200m² Terrasse mit 360º-Aussicht.",
          solarium: "Solarium",
          habitacion: "Zimmer",
          entradaIndependiente: "UNABHÄNGIGER EINGANG",
          salonesYterrazas: "Wohnzimmer und Terrasse.",
          plantaPrimeraDesc2: "En-Suite-Badezimmer mit Dusche.",
          plantaSegundaDesc4: "Das Personalhaus bietet 2 Schlafzimmer mit unabhängigem Bad und eine amerikanischen Küche.",
          footer: "©2020 Alle Rechte vorbehalten."
        }
      }
    },
    fallbackLng: "es",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
