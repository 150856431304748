import React from 'react'
import { useTranslation } from 'react-i18next'

const Environment = () => {
  const { t } = useTranslation()

  return <section id="campdemar" className="environment">
    <div className="container">
      <div className="top">
        <p className="title">Camp de Mar</p>
      </div>

      <div className="content">
        <div className="item">
          <h3 className="tit">{t('titEntorno')}</h3>
          <div className="icon">
            <i className="icon-mapamallorca"></i>
          </div>
          <p className="desc">{t('descEntorno')}</p>
        </div>

        <div className="item">
          <h3 className="tit">{t('titEntorno2')}</h3>
          <div className="icon">
            <i className="icon-playas"></i>
          </div>
          <p className="desc">{t('descEntorno2')}</p>
        </div>

        <div className="item">
          <h3 className="tit">{t('titEntorno3')}</h3>
          <div className="icon">
            <i className="icon-distancias"></i>
          </div>
          <ul className="list">
            <li>
              <span>{t('aeropuerto')}</span>
              <span className="dist">25 km</span>
            </li>
            <li>
              <span>{t('playa')}</span>
              <span className="dist">1 km</span>
            </li>
            <li>
              <span>Palma</span>
              <span className="dist">20 km</span>
            </li>
            <li>
              <span>Puerto de Andratx</span>
              <span className="dist">10 km</span>
            </li>
            <li>
              <span>{t('golf')}</span>
              <span className="dist">2 km</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
}

export default Environment