import React from 'react'
import logo from '../assets/images/logo.svg'
import { useTranslation } from 'react-i18next'

const Header = ({ moveToSection, isOpenNav, setIsOpenNav }) => {
  const { t, i18n } = useTranslation()
  
  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  const openNav = (e) => {
    e.preventDefault()
    if (isOpenNav === false) {
      setIsOpenNav(true)
    } else {
      setIsOpenNav(false)
    }
  }

  const closeNav = (e) => {
    e.preventDefault()
    if (isOpenNav === true) {
      setIsOpenNav(false)
    } else {
      setIsOpenNav(true)
    }
  }

  return <React.Fragment>
    <header className="header">
      <div className="container">
        <div className="navButtons">
          <button className="btnIcon openNav" onClick={openNav}><i className="icon-menu"></i></button>
        </div>

        <div className="logo">
          <img src={logo} alt="Villas Camp de Mar" />
        </div>
      </div>
    </header>

    <nav className={isOpenNav ? 'navMain open' : 'navMain'}>
      <div className="container">
        <button className="btnIcon close" onClick={closeNav}><i className="icon-cerrar"></i></button>       

        <ul className="lang">
          <li><button className={ i18n.language === 'es' ? 'btnLang active' : 'btnLang' } onClick={() => changeLanguage("es")}>ESP</button></li>
          <li><button className={ i18n.language === 'en' ? 'btnLang active' : 'btnLang' } onClick={() => changeLanguage("en")}>ENG</button></li>
          <li><button className={ i18n.language === 'de' ? 'btnLang active' : 'btnLang' } onClick={() => changeLanguage("de")}>ALE</button></li>
        </ul>

        <ul className="menu" >
          <li><a href="#description" onClick={(e) => moveToSection(e, 'description')}>{t('descripcion')}</a></li>
          <li><a href="#sheet" onClick={(e) => moveToSection(e, 'sheet')}>{t('casa')} / <span>{t('invitados')}</span></a></li>
          <li><a href="#services" onClick={(e) => moveToSection(e, 'services')}>{t('servicios')}</a></li>
          <li><a href="#gallery" onClick={(e) => moveToSection(e, 'gallery')}>{t('galeria')}</a></li>
          <li><a href="#campdemar" onClick={(e) => moveToSection(e, 'campdemar')}>Camp de Mar</a></li>
          <li><a href="#contact" onClick={(e) => moveToSection(e, 'contact')}>{t('contacto')}</a></li>
        </ul>
      </div>
    </nav>
  </React.Fragment>
}

export default Header