import React from 'react'
import LazyLoad from 'react-lazyload'
import { useTranslation } from 'react-i18next'
import Carousel from 'nuka-carousel'

import gallery01 from '../assets/images/gallery/gallery01.webp'
import gallery01b from '../assets/img/gallery/gallery01.jpg'
import gallery02 from '../assets/images/gallery/gallery02.webp'
import gallery02b from '../assets/img/gallery/gallery02.jpg'
import gallery03 from '../assets/images/gallery/gallery03.webp'
import gallery03b from '../assets/img/gallery/gallery03.jpg'
import gallery04 from '../assets/images/gallery/gallery04.webp'
import gallery04b from '../assets/img/gallery/gallery04.jpg'
import gallery05 from '../assets/images/gallery/gallery05.webp'
import gallery05b from '../assets/img/gallery/gallery05.jpg'
import gallery06 from '../assets/images/gallery/gallery06.webp'
import gallery06b from '../assets/img/gallery/gallery06.jpg'
import gallery07 from '../assets/images/gallery/gallery07.webp'
import gallery07b from '../assets/img/gallery/gallery07.jpg'
import gallery08 from '../assets/images/gallery/gallery08.webp'
import gallery08b from '../assets/img/gallery/gallery08.jpg'
import gallery09 from '../assets/images/gallery/gallery09.webp'
import gallery09b from '../assets/img/gallery/gallery09.jpg'
import gallery10 from '../assets/images/gallery/gallery10.webp'
import gallery10b from '../assets/img/gallery/gallery10.jpg'
import gallery11 from '../assets/images/gallery/gallery11.webp'
import gallery11b from '../assets/img/gallery/gallery11.jpg'
import gallery12 from '../assets/images/gallery/gallery12.webp'
import gallery12b from '../assets/img/gallery/gallery12.jpg'
import gallery13b from '../assets/img/gallery/exterior/B7K3944.jpg'
import gallery14b from '../assets/img/gallery/exterior/B7K3948.jpg'
import gallery15b from '../assets/img/gallery/exterior/B7K3949.jpg'
import gallery16b from '../assets/img/gallery/exterior/B7K3956.jpg'
import gallery17b from '../assets/img/gallery/exterior/B7K3962.jpg'
import gallery18b from '../assets/img/gallery/interior/26641328060_af2335d230_b.jpg'
import gallery19b from '../assets/img/gallery/interior/26641328330_dbffd05317_b.jpg'
import gallery20b from '../assets/img/gallery/interior/26641329610_366fe09d01_b.jpg'
import gallery21b from '../assets/img/gallery/interior/26641330330_826ca5d353_b.jpg'
import gallery22b from '../assets/img/gallery/interior/26641331860_884a795ff5_b.jpg'
import gallery23b from '../assets/img/gallery/interior/26641332150_f4a16d6622_b.jpg'
import gallery24b from '../assets/img/gallery/interior/26820639822_3bdbe30a3d_b_1.jpg'
import gallery25b from '../assets/img/gallery/interior/26820639822_3bdbe30a3d_b.jpg'
import gallery26b from '../assets/img/gallery/interior/26846592881_06ff3ce6cb_b.jpg'
import gallery27b from '../assets/img/gallery/interior/26846593471_979523e0dc_b.jpg'
import gallery28b from '../assets/img/gallery/interior/26880831126_0bbc56324b_b.jpg'
import arrowPrev from '../assets/icons/flecha_ant.svg'
import arrowNext from '../assets/icons/flecha_sig.svg'

const Gallery = () => {
  const { t } = useTranslation()

  return <section id="gallery" className="gallery">
    <div className="container">
      <div className="top">
        <p className="title">{t('galeria')}</p>
      </div>

      <div className="modGallery">
        <Carousel defaultControlsConfig={{
          wrapAround: true,
          nextButtonClassName: 'next',
          nextButtonStyle: {
            background: `url(${arrowNext}) no-repeat center center`,
            padding: 0,
            cursor: 'pointer'
          },
          prevButtonClassName: 'prev',
          prevButtonStyle: {
            background: `url(${arrowPrev}) no-repeat center center`,
            padding: 0,
            cursor: 'pointer'
          },
          pagingDotsStyle: {
            display: 'none'
          }
        }}>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery01} type="image/webp" />
              <source srcSet={gallery01b} type="image/jpeg" /> 
              <img src={gallery01b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery02} type="image/webp" />
              <source srcSet={gallery02b} type="image/jpeg" /> 
              <img src={gallery02b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery03} type="image/webp" />
              <source srcSet={gallery03b} type="image/jpeg" /> 
              <img src={gallery03b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery04} type="image/webp" />
              <source srcSet={gallery04b} type="image/jpeg" /> 
              <img src={gallery04b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery05} type="image/webp" />
              <source srcSet={gallery05b} type="image/jpeg" /> 
              <img src={gallery05b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery06} type="image/webp" />
              <source srcSet={gallery06b} type="image/jpeg" /> 
              <img src={gallery06b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery07} type="image/webp" />
              <source srcSet={gallery07b} type="image/jpeg" /> 
              <img src={gallery07b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery08} type="image/webp" />
              <source srcSet={gallery08b} type="image/jpeg" /> 
              <img src={gallery08b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery09} type="image/webp" />
              <source srcSet={gallery09b} type="image/jpeg" /> 
              <img src={gallery09b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery10} type="image/webp" />
              <source srcSet={gallery10b} type="image/jpeg" /> 
              <img src={gallery10b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery11} type="image/webp" />
              <source srcSet={gallery11b} type="image/jpeg" /> 
              <img src={gallery11b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery12} type="image/webp" />
              <source srcSet={gallery12b} type="image/jpeg" /> 
              <img src={gallery12b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery13b} type="image/jpeg" /> 
              <img src={gallery13b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery14b} type="image/jpeg" /> 
              <img src={gallery14b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery15b} type="image/jpeg" /> 
              <img src={gallery15b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery16b} type="image/jpeg" /> 
              <img src={gallery16b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery17b} type="image/jpeg" /> 
              <img src={gallery17b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery18b} type="image/jpeg" /> 
              <img src={gallery18b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery19b} type="image/jpeg" /> 
              <img src={gallery19b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery20b} type="image/jpeg" /> 
              <img src={gallery20b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery21b} type="image/jpeg" /> 
              <img src={gallery21b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery22b} type="image/jpeg" /> 
              <img src={gallery22b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery23b} type="image/jpeg" /> 
              <img src={gallery23b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery24b} type="image/jpeg" /> 
              <img src={gallery24b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery25b} type="image/jpeg" /> 
              <img src={gallery25b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery26b} type="image/jpeg" /> 
              <img src={gallery26b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery27b} type="image/jpeg" /> 
              <img src={gallery27b} alt="" />
            </picture>
          </LazyLoad>
          <LazyLoad height={535}>
            <picture>
              <source srcSet={gallery28b} type="image/jpeg" /> 
              <img src={gallery28b} alt="" />
            </picture>
          </LazyLoad>
        </Carousel>
      </div>
    </div>
  </section>
}

export default Gallery