import React, { useState } from 'react'
import axios from 'axios'
import LazyLoad from 'react-lazyload'
import { useTranslation } from 'react-i18next'
import Msgbox from './Msgbox'
import ReCAPTCHA from "react-google-recaptcha"
import mapa from '../assets/images/campdemar.webp'
import mapa2 from '../assets/img/campdemar.jpg'
import marker from '../assets/icons/marker.svg'

const Contact = () => {
  const { t } = useTranslation()
  const [message, setMessage] = useState({ body: '', classname: '' })
  const [human, setHuman] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const verifyCaptcha = (res) => {
    if(res) {
      setHuman(true)
    }
  }

  const expireCaptcha = () => {
    setHuman(false)
  }

  const handleSubmit = (event) => {
		event.preventDefault()
    const name = event.target.elements.name
    const surname = event.target.elements.surname
    const phone = event.target.elements.phone
		const email = event.target.elements.email
    const subject = "Te han escrito este mensaje desde el formulario de la web: "

		var data = { 
      name: name.value, 
      surname: surname.value, 
      phone: phone.value, 
      email: email.value, 
      subject: subject 
    }

    if (human){
      const regexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/ //eslint-disable-line
      if (regexp.test(email.value)) {
        axios.post(`https://villasluxury.herokuapp.com/emails/send_email`, data)
        .then((response) => {
          name.value = ""
          surname.value = ""
          phone.value = ""
          email.value = ""
          setMessage({ body: 'Your message has been sent, thanks!', classname: 'msg ok' })
        })
        .catch(function (error) {
          console.log(error);
        })
        console.log("--SeNd!--")
      } else {
        setMessage({ body: 'Your email is not correct', classname: 'msg error' })
      }
    } else {
      setMessage({ body: 'You have to verify the captcha', classname: 'msg error' })
    }
  }

  return <React.Fragment>
    <section id="contact" className="location">
      <div className="container">
        <div className="top">
          <p className="title">{t('comoLlegar')}</p>
          <div className="map">
            <a href="https://www.google.com/maps?ll=39.535978,2.423468&z=16&t=m&hl=es&gl=US&mapclient=embed&cid=5978513557606049252" target="_blank" rel="noopener noreferrer">
              <LazyLoad height={467}>
                <picture>
                  <source srcSet={mapa} type="image/webp" />
                  <source srcSet={mapa2} type="image/jpeg" /> 
                  <img src={mapa2} alt="map" />
                </picture>
              </LazyLoad>
              <span className="marker"><img src={marker} alt="marker" /></span>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className="content">
      <div className="container">
        <form className="formContact" onSubmit={handleSubmit}>
          <p className="title">{t('contacto')}</p>

          <div className="flex">
            <fieldset>
              <label>{t('nombre')}</label>
              <input type="text" className="formControl" name="name" />
            </fieldset>
            <fieldset>
              <label>{t('apellidos')}</label>
              <input type="text" className="formControl" name="surname" />
            </fieldset>
            <fieldset>
              <label>{t('telefono')}</label>
              <input type="text" className="formControl" name="phone" />
            </fieldset>
            <fieldset>
              <label>*{t('email')}</label>
              <input type="text" className="formControl" name="email" required={true} />
            </fieldset>
            <fieldset>
              <ReCAPTCHA sitekey="6LeLJP0ZAAAAAObvtCzggKNzC86XlH4id9xrw492" onChange={verifyCaptcha} onExpired={expireCaptcha} />
            </fieldset>
          </div>

          <button className="btn btnType2">{t('enviar')}</button>

          <Msgbox message={message.body} classname={message.classname} />
        </form>
      </div>
    </section>
  </React.Fragment>
}

export default Contact