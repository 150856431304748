import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()
  const [scrollPosition, setSrollPosition] = useState(0)
  const heightWindow = window.innerHeight

  const handleScroll = () => {
    const position = window.pageYOffset
    setSrollPosition(position)
  }

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [])

  return <React.Fragment>
    <footer className="footer">
      <div className="container">
        <button className={scrollPosition > heightWindow/2 ? 'btnIcon arrowTop on' : 'btnIcon arrowTop'} onClick={scrollTop}><i className="icon-flecha_top"></i></button>
        <p>{t('footer')}</p>
      </div>
    </footer>
  </React.Fragment>
}

export default Footer