import React from 'react'
import { useTranslation } from 'react-i18next'

const Servicios = () => {
  const { t } = useTranslation()

  return <section id="services" className="services">
    <div className="container">
      <div className="top">
        <p className="title white">{t('servicios')}</p>
      </div>

      <div className="content">
        <ul className="listServices">
          <li>
            <i className="icon-habitaciones"></i>
            <span>7 {t('habitaciones')}</span>
          </li>
          <li>
            <i className="icon-banyo"></i>
            <span>8 {t('banyos')}</span>
          </li>
          <li>
            <i className="icon-aseos"></i>
            <span>1 {t('aseo')}</span>
          </li>
          <li>
            <i className="icon-aireacondicionado"></i>
            <span>{t('aireAcondicionado')}</span>
          </li>
          <li>
            <i className="icon-calefaccion"></i>
            <span>{t('calefaccion')}</span>
          </li>
          <li>
            <i className="icon-wifi"></i>
            <span>{t('wifi')}</span>
          </li>
          <li>
            <i className="icon-television"></i>
            <span>{t('tvSatelite')}</span>
          </li>
          <li>
            <i className="icon-parking"></i>
            <span>{t('parking')}</span>
          </li>
          <li>
            <i className="icon-piscina"></i>
            <span>{t('piscinaExt')}</span>
          </li>
          <li>
            <i className="icon-spa"></i>
            <span>{t('spa')}</span>
          </li>
          <li>
            <i className="icon-jacuzzi"></i>
            <span>{t('jacuzziExt')}</span>
          </li>
          <li>
            <i className="icon-equipada"></i>
            <span>{t('equipada')}</span>
          </li>
          <li>
            <i className="icon-cuna"></i>
            <span>{t('cuna')}</span>
          </li>
          <li>
            <i className="icon-ascensor"></i>
            <span>{t('ascensor')}</span>
          </li>
          <li>
            <i className="icon-covidfree"></i>
            <span>Covid free</span>
          </li>
          <li>
            <i className="icon-plegatin"></i>
            <span>{t('plegatin')}</span>
          </li>
          <li>
            <i className="icon-casita"></i>
            <span>{t('apartamento')}</span>
          </li>
        </ul>
      </div>
    </div>
  </section>
}

export default Servicios