import React from 'react'
import { useTranslation } from 'react-i18next'

const Prices = () => {
  const { t } = useTranslation()

  return <section id="prices" className="prices">
    <div className="container">
      <div className="top">
        <p className="title">{t('alquilerYventa')}<br /> {t('tarifas')} 2022</p>
      </div>

      <div className="content">
        <div className="rent">
          <div className="item">
            <p>{t('noviembreAfebrero')}:<br /> {t('precio1')}/{t('semana')}</p>
            <span>({t('minimo')} 4 {t('dias')})</span>
          </div>

          <div className="item">
            <p>{t('marzoAmayo')}:<br /> {t('precio2')}/{t('semana')}</p>
            <span>({t('minimo')} 7 {t('dias')})</span>
          </div>

          <div className="item">
            <p>{t('junioAoctubre')}:<br /> {t('precio3')}/{t('semana')}</p>
            <span>({t('minimo')} 7 {t('dias')})</span>
          </div>
        </div>

        <div className="sale">
          <p>{t('venta')}: {t('precio4')}</p>
        </div>
      </div>
    </div>
  </section>
}

export default Prices