import React from 'react';

const Msgbox = props => {
  let classname = props.classname;

  return <div className="contMsg">
    <p className={classname}>{props.message}</p>
  </div>
}

export default Msgbox;