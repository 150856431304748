import React, { useState, useEffect } from 'react'
import LazyLoad from 'react-lazyload'
import { useTranslation } from 'react-i18next'
import video from '../assets/video/CampdeMar.mp4'

const Description = () => {
  const { t } = useTranslation()
  const [isOpenOverlay, setIsOpenOverlay] = useState(false)

  const openOverlay = (e) => {
    e.preventDefault()
    if (isOpenOverlay === false) {
      setIsOpenOverlay(true)
    } else {
      setIsOpenOverlay(false)
    }
  }

  const closeOverlay = (e) => {
    e.preventDefault()
    if (isOpenOverlay === true) {
      setIsOpenOverlay(false)
    } else {
      setIsOpenOverlay(true)
    }
  }

  useEffect(() => {
    setIsOpenOverlay(false)
  }, [])

  return <React.Fragment>
    <section id="description" className="description">
      <div className="container">
        <div className="top">
          <p className="title">{t('descripcion')}</p>
        </div>
        <div className="flex">
          <div className="left">
            <p>{t('descripcion1')}</p>
          </div>
          <div className="right">
            <p>{t('descripcion2')}</p>
          </div>
        </div>
      </div>
    </section>

    <section id="sheet" className="sheet">
      <div className="container">
        <div className="top">
          <span className="ima f16x9">
          <div className="video">
            <LazyLoad height={535}><video autoPlay controls loop muted><source src={video} type="video/mp4" /></video></LazyLoad>
          </div>
          </span>
        </div>

        <div className="middle">
          <div className={ !isOpenOverlay ? 'content flex' : 'content flex hidden' }>
            <div className="modSheet">
              <p className="title">{t('titVilla')}</p>
              <p className="desc">{t('descVilla')}</p>
              <button className="btn btnType1" onClick={openOverlay}>{t('verFicha')}</button>
            </div>

            <div className="modSheet">
              <p className="title">{t('titVilla2')}</p>
              <p className="desc">{t('descVilla2')}</p>
              <button className="btn btnType1" onClick={openOverlay}>{t('verFicha')}</button>
            </div>
          </div>

          <div className={ isOpenOverlay ? 'overlay flex open' : 'overlay flex' }>
            <button className="btnIcon close" onClick={closeOverlay}>
              <i className="icon-cerrar"></i>
            </button>

            <div className="modSheet">
              <p className="title">{t('titVilla')}</p>
              <ul className="icons">
                <li>
                  <i className="icon-superficie"></i>
                  <span>{t('superficie')} 780m²</span>
                </li>
                <li>
                  <i className="icon-habitacion"></i>
                  <span>4 {t('habitaciones')}</span>
                </li>
                <li>
                  <i className="icon-plantas"></i>
                  <span>{t('plantas')}</span>
                </li>
                <li>
                  <i className="icon-banyo_gris"></i>
                  <span>5 {t('banyosSuite')}</span>
                </li>
                <li>
                  <i className="icon-ascensor_black"></i>
                  <span>{t('ascensor')}</span>
                </li>
                <li></li>
              </ul>
              <div className="levels">
                <p>
                  <span className="desc"><strong>{t('spa')}</strong> ({t('sotanoDesc')}).</span>
                </p>
                <p>
                  <span className="tit">{t('plantaBaja')}:</span>
                  <span className="desc">{t('plantaBajaDesc')}</span>
                </p>
                <p>
                  <span className="tit">{t('plantaPrimera')}:</span>
                  <span className="desc"><strong>{t('habitacionPrincipal')}:</strong> {t('plantaPrimeraDesc')}</span>
                </p>
                <p>
                  <span className="tit">{t('plantaSegunda')}:</span>
                  <span className="desc"><strong>{t('habitacion')} 2:</strong> {t('plantaSegundaDesc1')}</span>
                  <span className="desc"><strong>{t('habitacion')} 3:</strong> {t('plantaSegundaDesc2')}</span>
                  <span className="desc"><strong>{t('habitacion')} 4:</strong> {t('plantaSegundaDesc3')}</span> 
                </p>
                <p>
                  <span className="tit">{t('terceraPlanta')}:</span>
                  <span className="desc"><strong>{t('solarium')}:</strong> {t('terceraPlantaDesc')}</span>
                </p>
              </div>
            </div>

            <div className="modSheet">
              <p className="title">{t('titVilla2')}</p>
              <ul className="icons">
                <li>
                  <i className="icon-superficie"></i>
                  <span>{t('superficie')} 290m²</span>
                </li>
                <li>
                  <i className="icon-habitacion"></i>
                  <span>3 {t('habitaciones')}</span>
                </li>
                <li>
                  <i className="icon-entradaindependiente"></i>
                  <span>{t('entradaIndependiente')}</span>
                </li>
                <li>
                  <i className="icon-banyo_gris"></i>
                  <span>3 {t('banyosSuite')}</span>
                </li>
                <li>
                  <i className="icon-casita_black"></i>
                  <span>{t('apartamento')}</span>
                </li>
                <li></li>
              </ul>
              <div className="levels">
                <p>
                  <span className="tit">{t('plantaBaja')}:</span>
                  <span className="desc">{t('salonesYterrazas')}</span>
                </p>
                <p>
                  <span className="tit">{t('plantaPrimera')}:</span>
                  <span className="desc"><strong>{t('habitacion')} 5:</strong> 26m², {t('plantaPrimeraDesc2')}</span>
                </p>
                <p>
                  <span className="tit">{t('plantaSegunda')}:</span>
                  <span className="desc"><strong>{t('habitacion')} 6:</strong> 26m², {t('plantaPrimeraDesc2')}</span>
                  <span className="desc"><strong>{t('habitacion')} 7:</strong> 26m², {t('plantaPrimeraDesc2')}</span>
                  <span className="desc">{t('plantaSegundaDesc4')}</span>
                </p>
                <p>
                  <span className="tit">{t('apartamento')}:</span>
                  <span className="desc">{t('apartamentoDesc')}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
}

export default Description