import React, { useState } from 'react'
import './assets/css/style.css'
import Header from './components/Header'
import Slideshow from './components/Slideshow'
import Description from './components/Description'
import Services from './components/Services'
import Gallery from './components/Gallery'
import Environment from './components/Environment'
import Prices from './components/Prices'
import Contact from './components/Contact'
import Footer from './components/Footer'

function App() {
  const [isOpenNav, setIsOpenNav] = useState(false)

  const moveToSection = (e, section) => {
    e.preventDefault()
    setIsOpenNav(false)
    document.getElementById(section).scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <React.Fragment>
      <Header moveToSection={moveToSection} isOpenNav={isOpenNav} setIsOpenNav={setIsOpenNav} />
      <main>
        <Slideshow moveToSection={moveToSection} />
        <Description />
        <Services/>
        <Gallery />
        <Environment />
        <Prices />
        <Contact />
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default App;
